import axios from 'axios'
import { Form, Formik, FormikProps } from 'formik'
import * as React from 'react'
import * as Yup from 'yup'

import Button from '../../components/button/button'
import Input from '../../components/input/input'
import { ContactFromWrapper, ContactPageSuccess, ContactPageTitle, ContactWrapper, InputGroup } from './style'

interface MyFormValues {
  firstName: string;
  email: string;
  message: string;
}

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  message: Yup.string().required('Required'),
});

const Contact: React.SFC<{}> = () => {
  const [successMessage, setSuccessMessage] = React.useState('')

  return (
    <Formik
      initialValues={{ firstName: '', email: '', message: '' }}
      onSubmit={(values: MyFormValues, actions: any) => {
        setSuccessMessage('')

        axios
          .post(
            'https://formspree.io/f/xwkwyleq',
            values,
            { headers: { Accept: 'application/json' } }
          )
          .then(() => {
            actions.resetForm();
            actions.setSubmitting(false);
            setSuccessMessage('Thank you very much for sending me your message!')
          })
      }}
      validationSchema={SignupSchema}
      render={({
        handleChange,
        values,
        errors,
        handleBlur,
        touched,
        isSubmitting,
      }: FormikProps<MyFormValues>) => (
        <>
          <Form>
            <ContactWrapper>
              <ContactPageTitle>
                <h2>Contact</h2>
                <p>
                  If you want to contact me, or just say hello, you can use the contact form below. I encourage you to share your thoughts about this place. Maybe you have some ideas, how to make it better - I would be appreciated by any tips!
                </p>
              </ContactPageTitle>
              {successMessage && <ContactPageSuccess>{successMessage}</ContactPageSuccess>}
              <ContactFromWrapper>
                <InputGroup>
                  <Input
                    type='text'
                    name='firstName'
                    value={`${values.firstName}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Name'
                    notification={`${
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ''
                    }`}
                  />
                  <Input
                    type='email'
                    name='email'
                    value={`${values.email}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Email'
                    notification={`${
                      errors.email && touched.email ? errors.email : ''
                    }`}
                  />
                </InputGroup>
                <Input
                  type='textarea'
                  name='message'
                  value={`${values.message}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label='Message'
                  notification={
                    errors.message && touched.message ? errors.message : ''
                  }
                />
                <Button
                  title='Submit'
                  type='submit'
                  isLoading={isSubmitting ? true : false}
                  loader='Submitting...'
                />
              </ContactFromWrapper>
            </ContactWrapper>
          </Form>
        </>
      )}
    />
  );
};

export default Contact;
